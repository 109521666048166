import React from 'react'
import PropTypes from 'prop-types'
import TableTemplate from "components/TableTemplate"
import { Link } from "react-router-dom"

const AlternateHome = props => {
  const columns = [


    { name: "Title", selector: row => row?.title },
    { name: "Category", selector: row => row?.category },
    {
      name: "Actions",

      cell: (row) => {
        return (
          <div className="d-flex justify-content-center "
          >
            <Link
              className="btn btn-sm text-primary text-nowrap "
              to={`/user-profile/${row?._id}`}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                View
              </p>
            </Link>
            <button
              className="btn btn-sm text-info text-nowrap "
              onClick={() => {
                setShowAddEditUser(true)
                setSelected(row)
                setToEdit(true)
              }}
            >
              <p

                style={{ fontSize: 11 }}
                className="ms-2  my-0 py-0"
              >
                Edit
              </p>
            </button>


            <button className="btn btn-sm text-danger text-nowrap "
              onClick={() => {
                setShowDeleteUser(true)
                setSelected(row)
              }}>
              Delete
            </button>


          </div>
        )
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px'
    },
    { name: "Type", selector: row => row?.fileType },
    { name: "Created", selector: row => moment(row?.created).format('LL'), }


  ]

  return (
    <div>
      <TableTemplate
        rows={["", ""]}
        columns={columns}
        dataLoaded={true}
      />
    </div>
  )
}

AlternateHome.propTypes = {}

export default AlternateHome