import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { Col, Container, Form, InputGroup, Row, Spinner } from "react-bootstrap"
import { SyncLoader } from "react-spinners"

import * as file from "models/files";
import toast from "react-hot-toast";

import FileCard from "components/FileCard"
import CategorySelector from "components/CategorySelector";
import AddEditUser from "./AddEditUser";
import FileViewer from "components/FileViewer";


const Home = (prop) => {
  const [files, setFiles] = useState([])
  const [listedFiles, setListedFiles] = useState([])
  const [searchResult, setSearchResult] = useState([])
  const [downloadReady, setDownloadReady] = useState(true)
  const [signTrigger, setSignTrigger] = useState(false)
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [selected, setSelected] = useState(null)


  const [query, setQuery] = useState({})
  const [activeFileTab, setActiveFileTab] = useState({
    name: "All", value: "All"
  })

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)


  const fileTabs = [
    { name: "All", value: "All", icon: "fas fa-file" },
    { name: "PDF", value: "pdf", icon: "fas fa-file" },
    { name: "Audio", value: "audio", icon: "fas fa-volume-up" },
    { name: "Video", value: "video", icon: "fas fa-video" },

  ]

  const countyTabs = [
    { name: "All", value: "All" },
    { name: "Kilifi", value: "Kilifi" },
    { name: "Kitui", value: "Kitui" },
    { name: "Kwale", value: "Kwale" },
    { name: "Turkana", value: "Turkana" },
  ]

  const onFilter = e => {
    const searchValue = e.target.value
    setSearchText(searchValue)
    //getSearchProducts(e.target.value)
  }
  const getAllFiles = (params) => {

    file.getAllFiles(params)
      .then(resp => {
        const data = resp.files
        setListedFiles(data)
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        console.log(err)
        toast.error(err?.response?.data?.message)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    setShowFileViewer(false)
    getAllFiles(query)
  }, [query])
  useEffect(() => {
    setShowFileViewer(false)
    getAllFiles(`search=${prop.searchText}`)
  }, [prop.searchText])
  useEffect(() => {
    setShowFileViewer(false)
    setIsLoading(true)
    getAllFiles()
  }, [])

  useEffect(() => {
    const filterbyCategory = prop?.selectedCategory === "All" ?
      "" : `category=${prop.selectedCategory}`
    const filterbyFile = activeFileTab.value === "All" ? "" : `fileType=${activeFileTab.value}`
    const filterbyCounty = prop.activeCountyTab.value === "All" ? "" : `county=${prop.activeCountyTab?.value}`
    const preq = filterbyCategory + `&${filterbyFile}` + `&${filterbyCounty}`
    setQuery(preq)
  }, [prop?.selectedCategory, prop?.activeCountyTab, activeFileTab])
  return (
    <div className="page-content bg-white ">
      <Container fluid className="pb-5 mb-5">
        <Col xs={12} md={8} lg={12} className=" d-flex justify-content-betweend  bg-light  p-1">

          <Col xs={12} md={8} lg={4} className=" d-flex justify-content-between ">
            {fileTabs.map(((item, index) => (
              <div
                key={index}
                className={`d-flex align-items-center justify-content-center border-3 btn border-0 border-bottom btn-sm me-md-3
                  
                  bg-${activeFileTab?.name === item?.name ? "primary" : "white"} 
                  text-${activeFileTab?.name === item.name ? "white" : "primary"}
                  `}
                href={`#${item.name}`}
                onClick={() => setActiveFileTab({ name: item.name, value: item?.value })}
              >
                <i className={`${item?.icon}`}></i>
                <h6 className={`mx-1 my-0 `} >
                  {item.name}
                </h6>
              </div>


            )))}
          </Col>




        </Col>
        {/* <div className="d-block d-md-none">

          <CategorySelector categories={categoryList} />
        </div> */}
        <Row className="d-flex pb-5" >
          {isLoading ?
            <div className="d-flex p-5 my-5 justify-content-center">
              <SyncLoader
                color='#082F1A'
                size={20}
                margin={10}
              />

            </div> : <>
              {listedFiles.length === 0 ? <div className=" d-flex text-primary justify-content-center  my-5">
                <h5>The are no files for this section</h5>
              </div> :
                <div className={`d-${showFileViewer ? "none" : "flex"} d-md-flex   flex-wrap col col-md-${showFileViewer ? "6" : "12"} pb-5`}>
                  {listedFiles.map((item, index) => (
                    <FileCard
                      key={index}
                      item={item}
                      downloadReady={downloadReady}
                      signTrigger={signTrigger
                      }
                      setSignTrigger={setSignTrigger}
                      setFileViewer={setShowFileViewer}
                      fileViewer={showFileViewer}
                      setSelected={setSelected}
                    />
                  ))}
                </div >}
            </>}

          {showFileViewer && (

            <FileViewer
              selected={selected}
              setSelelected={setSelected}
              setFileViewer={setShowFileViewer}
              fileViewer={showFileViewer}

            />

          )}


        </Row>
      </Container>

    </div>
  )
}

Home

export default Home