import PropTypes from 'prop-types';
import React, { useState } from "react";

import { connect } from "react-redux";
import { Col, Container, Dropdown, Form, InputGroup, Row } from "react-bootstrap"
import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "components/CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "assets/images/megamenu-img.png";

// import images

import logo from "assets/images/Kara-Logo.png";
import logoLightSvg from "assets/images/Kara-Logo.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "store/actions";


const Header = props => {
  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const [searchResult, setSearchResult] = useState([])

  const [searchText, setSearchText] = useState("")
  const [activeFileTab, setActiveFileTab] = useState({
    name: "All Files", value: "All"
  })


  const fileTabs = [
    { name: "PDF", value: "pdf", icon: "fas fa-file" },
    { name: "Audio", value: "audio", icon: "fas fa-volume-up" },
    { name: "Video", value: "video", icon: "fas fa-video" },

  ]

  const countyTabs = [
    { name: "Kilifi", value: "Kilifi" },
    { name: "Kitui", value: "Kitui" },
    { name: "Kwale", value: "Kwale" },
    { name: "Turkana", value: "Turkana" },
  ]



  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  const onFilter = e => {
    const searchValue = e.target.value
    props.setSearchText(searchValue)
    //getSearchProducts(e.target.value)
  }


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-2  font-size-16 header-item d-block d-lg-none"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
            <div className="navbar-brand-boxs d-md-none   ps-0 ">
              <Link to="/" className="logo logo-dark ">
                <span className="logo-sm">
                  <img src={logo} alt="" height="42" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light ">
                <span className="logo-sm">
                  <img src={logo} alt="" height="30" />
                </span>
              </Link>
            </div>
            <div className=" d-none d-md-block  justify-content-start  mx-lg-5 ">
              <Link to="/" className=" ">
                <span className="logo-md">
                  <img src={logo} alt="" height="55" />
                </span>
              </Link>
            </div>
            <div className="d-md-flex d-none align-items-end  ">
              <h1 className="text-primary m-0 d-none d-lg-block ms-2 p-0 pb-1 fw-light"> Digital Space Learning Hub</h1>

            </div>
            {/* <div className="d-md-flex d-none align-items-end pb-2  ">
              <h4 className="text-primary d-flex d-lg-none m-0 ms-2 p-0 pb-1 fw-light"> Digital Learning Hub</h4>
            </div> */}
            {/* <div className="d-flex align-items-center  ">
              <h6 className="text-primary m-0 p-0 fw-light"> Digital Learning Hub</h6>
            </div> */}
            {/* <button className="btn btn-primary ms-2">
              <i className="mdi mdi-filter" />  Search
            </button> */}

          </div>
          <div className="d-flex align-items-center">

            {/* <Dropdown className=" ms-2 me-3 rounded" style={{ minWidth: 150 }}>
              <Dropdown.Toggle
                variant=""
                id="dropdown-basic"
                className="d-flex justify-content-between rounded-0 border dropdown-toggle "
                style={{ minWidth: 150 }}
              >
                {activeFileTab?.name}{" "}
                <i className="mdi mdi-chevron-down ms-1 " />
              </Dropdown.Toggle>

              <Dropdown.Menu className="w-100">
                <Dropdown.Item
                  onClick={() => {
                    setActiveFileTab({ name: "All Files", value: "All" })
                  }}
                >
                  All Files
                </Dropdown.Item>
                {fileTabs.map((item, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Dropdown.Item
                    key={index}
                    onClick={() => setActiveFileTab({ name: item.name, value: item?.value })}
                    className='text-truncate'
                  >
                    <i className={`${item?.icon}`}></i> {item.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="d-flex ">
              <Dropdown className=" ms-2 rounde text-primary border border-0 border-primary " style={{ minWidth: 100 }}>
                <Dropdown.Toggle
                  variant=""
                  id="dropdown-basic"
                  className="d-flex justify-content-between rounded-0 border border-0 dropdown-toggle bg-light "
                  style={{ minWidth: 100 }}
                >
                  {props.activeCountyTab?.name}{" "}
                  <i className="mdi mdi-chevron-down ms-1 " />
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100">
                  <Dropdown.Item
                    onClick={() => {
                      props.setActiveCountyTab({ name: "All Counties", value: "All" })
                    }}
                  >
                    All Counties
                  </Dropdown.Item>
                  {countyTabs.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Dropdown.Item
                      key={index}
                      onClick={() => props.setActiveCountyTab({ name: item.name, value: item?.value })}
                      className='text-truncate'
                    >
                      {item.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="justifycontent-end   align-items-center search-bar-container   rounded-0  d-none ">

              <InputGroup className="rounded rounded-0 bg-white search-bar " style={{ width: 400 }}>
                <span className="fw-bold input-group-text bg-primary border-15 rounded rounded-0 text-white">
                  {/* <i className="fa fa-search" /> */}
                  Search
                </span>
                <Form.Control
                  value={searchText}
                  onChange={onFilter}
                  placeholder="Type to search..."
                  className="border-1 shadow-none rounded rounded-0 "
                  size="lg"
                />
                <button
                  type="button"
                  onClick={() => setSearchText("")}
                  className={`btn  rounded rounded-0 text-primary fw-bold py-1 border  border-1 ${searchText.length > 0 ? "" : "d-none"
                    }`}
                >
                  X
                </button>

                {searchText != "" &&
                  <div className="search-list">
                    {isLoading ? (
                      <div>
                        <div className="p-2 text-black">
                          Loading...
                        </div>
                      </div>
                    ) : (
                      <div>
                        {searchResult.length === 0 ? <div>
                          <div className="p-2 py-3 text-black">
                            No results found
                          </div>
                        </div> :
                          <div className="  border d-flex flex-column text-primary">

                            {searchResult.map((item => (
                              <Link key={item?._id} to={`/product-detail/${item?._id}`} className="px-2">
                                {item?.fileName}
                              </Link>
                            )))}
                            <div className="border border-1 px-2">
                              Total files {totalFiles}
                            </div>
                          </div>}
                      </div>
                    )}
                  </div>}

              </InputGroup>

            </div>
            <div className="dropdown d-inline-block  ">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon px-1 "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <div className="justifycontent-end   align-items-center search-bar-container   rounded-0   ">

                  <InputGroup className="rounded rounded-0 bg-white search-bar border-dark " >
                    <span className="fw-bold input-group-text bg-primary border-15 rounded rounded-0 text-white">
                      {/* <i className="fa fa-search" /> */}
                      Search
                    </span>
                    <Form.Control
                      value={props.searchText}
                      onChange={onFilter}
                      placeholder="Type to search..."
                      className="border-1 shadow-none border-dark rounded rounded-0 "
                      size="md"
                    />
                    <button
                      type="button"
                      onClick={() => setSearchText("")}
                      className={`btn  rounded rounded-0 text-primary fw-bold py-1 border  border-1 border-dark ${props.searchText.length > 0 ? "" : "d-none"
                        }`}
                    >
                      X
                    </button>

                    {/* {props.searchText != "" &&
                      <div className="search-list">
                        {isLoading ? (
                          <div>
                            <div className="p-2 text-black">
                              Loading...
                            </div>
                          </div>
                        ) : (
                          <div>
                            {searchResult.length === 0 ? <div>
                              <div className="p-2 py-3 text-black">
                                No results found
                              </div>
                            </div> :
                              <div className="  border d-flex flex-column text-primary">

                                {searchResult.map((item => (
                                  <Link key={item?._id} to={`/product-detail/${item?._id}`} className="px-2">
                                    {item?.fileName}
                                  </Link>
                                )))}
                                <div className="border border-1 px-2">
                                  Total files {totalFiles}
                                </div>
                              </div>}
                          </div>
                        )}
                      </div>} */}

                  </InputGroup>

                </div>
              </div>
            </div>





            <div className="dropdown d-none d-lg-inline-block ">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>


            {/* <ProfileMenu /> */}


          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
