
// main API URL
let baseUrl = "https://api.steticduka.co.ke";

//"https://kara-emcx.onrender.com/"
//"http://localhost:3001";

export const getApi = {
  ACCOUNTS: {
    LOGIN: `${baseUrl}/user/signin`,
    REGISTER: `${baseUrl}/users/register`,
    REGISTER_AS_ADMIN: `${baseUrl}/users/registerByAdmin`,
    GET_ALL_USERS: `${baseUrl}/users/getallusers`,
    GET_USER_DETAILS: (id) => `${baseUrl}/users/getUserById/${id}`,
    UPDATE_USER: `${baseUrl}/users/updateUserDetails`,
    UPDATE_PASSWORD: () => `${baseUrl}/users/updatePassword/${id}`,
    ACTIVATE_USER: `${baseUrl}/users/activate`,
    DEACTIVATE_USER: `${baseUrl}/users/de-activate`,
    DELETE_USER: (id) => `${baseUrl}/users/deleteUser/${id}`,
    FORGET_PASSWORD: `${baseUrl}/users/forgetPassword`,
    SET_PASSWORD: `${baseUrl}/users/setNewPassword`,

  },

  LOGS: {
    // items
    GET_ALL_LOGS: `${baseUrl}/logs/getAllLogs`,


  },
  LISTINGS: {
    //get listing
    GET_ALL_LISTINGS: `${baseUrl}/listings/getAllListings`,
    GET_LISTINGS_BY_ID: (listingId) => `${baseUrl}/listings/getListingById/${listingId}`,
    GET_LISTINGS_BY_OWNER_ID: (ownerId) => `${baseUrl}/listings/getListingByOwner/${ownerId}`,

    //post
    ADD_LISTING: `${baseUrl}/listings/addListing`


  },

  FILES: {
    //GET
    GET_ALL_FILES: `${baseUrl}/files/getAllFiles`,
    GET_FILE_BY_ID: `${baseUrl}/files/getFileById`,

    //POST
    ADD_FILE: `${baseUrl}/files/addFile`,
    //PUT
    UPDATE_FILE: `${baseUrl}/files/updateFile`,
    //DELETE
    DELETE_FILE: `${baseUrl}/files/deleteFile`,
  },

  SETUP: {
    //category
    GET_ALL_CATEGORIES: `${baseUrl}/categories/getAllCategories`,
    GET_CATEGORY_BY_ID: `${baseUrl}/categories/getCategoryById`,
    GET_CATEGORY_BY_NAME: `${baseUrl}/categories/getCategoryByName`,
    //POST
    ADD_CATEGORY: `${baseUrl}/categories/addCategory`,
    //PUT
    UPDATE_CATEGORY: `${baseUrl}/categories/updateCategory`,
    //DELETE
    DELETE_CATEGORY: `${baseUrl}/categories/deleteCategory`,

  }
};
