import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "pages/Authentication/user-profile"

// Authentication related pages


// Dashboard
import Dashboard from "pages/Dashboard/index"
import Home from "pages/Home"
import Login from "pages/Authentication/Login"
import ForgetPassword from "pages/Authentication/ForgetPassword"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/", component: Home },



  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPassword },
  //{ path: "/register", component:  },
]

export { authProtectedRoutes, publicRoutes }
