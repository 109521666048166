import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row className="text-secondary">
            <Col md={4}>
              <Link
                className="text-secondary  "
                to={`/`}
              >

                Home

              </Link>
              <div>
                About
              </div>
              <div>
                Contact
              </div>
              <div>
                FAQs
              </div>
              <div>
                Careers
              </div>
            </Col>
            <Col md={4}>
              <div className="text-sm-center d-none d-sm-block">
                {new Date().getFullYear()} © Kara.
              </div>
            </Col>
            <Col md={4} className="text-secondary">
              <div className="text-sm-center d-none d-sm-block">
                Isiara Place Estate, Hse 12,
              </div>
              <div className="text-sm-center d-none d-sm-block">
                Kingara Close off Kingara Rd,
              </div>
              <div className="text-sm-center d-none d-sm-block">
                P.O Box 1411-00100
              </div>
              <div className="text-sm-center d-none d-sm-block">
                G.P.O Nairobi, Kenya
              </div>
              <div className="text-sm-center d-none d-sm-block">
                020-3874331, 0725983445
              </div>
              <div className="text-sm-center d-none d-sm-block">
                mail@kara.or.ke
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
