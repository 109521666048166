import React, { Fragment, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "react-bootstrap"
import AuthCarousel from "./AuthCarousel"

const AuthBody = ({ props, meta, form }) => {
  useEffect(() => {
    if (localStorage.getItem("authMQPI")) {
      // localStorage.removeItem("authMQPI");
      window.location = "/dashboard"
    }
  }, [])

  return (
    <Fragment>
      <MetaTags>
        <title>{meta}</title>
      </MetaTags>
      <div className="auth-full-bg p-0 m-0 ">
        <Container fluid className="p-0">
          <div className="auth-body-wrap ">
            <div className="auth-form-wrapper ">{form}</div>
            {/* <AuthCarousel /> */}
          </div>
        </Container>
      </div>
    </Fragment>
  )
}

export default AuthBody
