import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//Post Funtions
export const register = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.REGISTER, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

export const registerAsAdmin = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.REGISTER_AS_ADMIN, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

export const login = async (payload) => {
  return await axios
    .post(getApi.ACCOUNTS.LOGIN, payload)
    .then(async ({ data }) => {

      return data;
      // // Saving User Profile Data
      // const profile = data.profile;
      // // get session expiry time
      // const endTime = Date.now() + (profile.expiresIn * 1000);
      // // save expiry time to profile
      // profile['endTime'] = endTime;
      // // save profile
      // localStorage.setItem("authCoCAdmin", JSON.stringify(profile));

      // return true;
    })
    .catch(err => catchError(err, err));
};
//get Funtions
export const getUserDetails = async (id) => {
  return await axios
    .get(getApi.ACCOUNTS.GET_USER_DETAILS(id))
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

export const getAllUsers = async (params) => {
  return await axios
    .get(`${getApi.ACCOUNTS.GET_ALL_USERS}?${params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put Functions

export const updateProfile = async (payload) => {
  return await axios
    .put(`${getApi.ACCOUNTS.UPDATE_USER}`, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

export const updatePassword = async (userId, payload) => {
  return await axios
    .put(`${getApi.ACCOUNTS.UPDATE_PASSWORD(userId)}`, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

export const activateUser = async (userId) => {
  return await axios
    .put(`${getApi.ACCOUNTS.ACTIVATE_USER}/${userId}`,)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};

//Delete Funtions

export const deleteUser = async (userId) => {
  return await axios
    .delete(`${getApi.ACCOUNTS.DELETE_USER(userId)}`,)
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err.message))
}

export const deactivateUser = async (userId) => {
  return await axios
    .put(`${getApi.ACCOUNTS.DEACTIVATE_USER}/${userId}`, {})
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, 'Login error'));
};



export const getLoggedInUserData = () => {
  return JSON.parse(localStorage.getItem("authCoCAdmin"));
};

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem("authCoCAdmin"))?.token;
};



export const forgotPassword = async (payload,) => {
  return await axios
    .post(`${getApi.ACCOUNTS.FORGET_PASSWORD}`, payload, {})
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const setPassword = async (payload) => {
  return await axios
    .post(`${getApi.ACCOUNTS.SET_PASSWORD}`, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

