import React from "react"
import { Container, Row, Col } from "reactstrap"
import NetherlandsLogo from "assets/images/Netherlands.PNG";
import AmkeniLogo from "assets/images/Amkeni.jpg";
import UNDPLogo from "assets/images/UNDP.jpeg";
import { Carousel } from "react-bootstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer  bg-white pt-0 px-0 m-0">
        <Container fluid={true}>
          <div className="text-center p-0 m-0  bg-white"
          ><h1 className="text-primary"> Our Partners</h1></div>
          <Row className="bg-white justify-content-center d-none d-md-flex m-0 p-0">
            <Col md={3}>
              <img
                src={UNDPLogo}
                //width="%"
                height={80}
              />
            </Col>
            <Col md={3}>
              <img
                src={AmkeniLogo}
                width="80%"
              />
            </Col>
            <Col md={3}>
              <img
                src={NetherlandsLogo}
                width="80%"
              />
            </Col>


          </Row>
          <Row className="d-flex d-md-none">
            <Carousel
              indicatorLabels={false}
              indicators={false}>
              <Carousel.Item >

                <div className=" d-flex justify-content-center">
                  <img
                    src={UNDPLogo}
                    //width="%"
                    height={80}
                  />
                </div>

              </Carousel.Item>
              <Carousel.Item >   <div className=" d-flex justify-content-center">
                <img
                  src={AmkeniLogo}

                  height={80}
                />
              </div>

              </Carousel.Item>
              <Carousel.Item >
                <div className=" d-flex justify-content-center">
                  <img
                    src={NetherlandsLogo}

                    height={80}
                  />
                </div>

              </Carousel.Item>
            </Carousel>
          </Row>
          <Row className="text-white text-center pt-3 mt-1 bg-white
           ">


            {/* <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                Isiara Place Estate, Hse 12,
                Kingara Close off Kingara Rd,
                P.O Box 1411-00100
                G.P.O Nairobi, Kenya

                020-3874331, 0725983445
                mail@kara.or.ke
              </div>
            </Col> */}
          </Row>
          <Row className="text-primary text-center pt-3  bg-white
           ">
            <Col >
              <h5>{new Date().getFullYear()} © Digital Space Learning Hub</h5>

            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
