const tableStyle = {
  headRow: {
    style: {
      // backgroundColor: "#be150f",
      // color: "white",
      fontWeight: "bold",
      fontSize: 11,
      borderBottom: "none",
      paddingTop: "0.5rem !important",
      paddingBottom: "0.5rem !important",
      marginBottom: "0.125rem !important",
    },
    // denseStyle: {
    //   paddingTop: "1rem",
    //   paddingBottom: "1rem",
    // },
  },
  // responsiveWrapper: {
  //   style: {
  //     borderRadius: "0.25rem",
  //     boxShadow: "#0002 0px 3px 8px",
  //   },
  // },
  // rows: {
  //   stripedStyle: {
  //     backgroundColor: "#00000009",
  //   },
  //   highlightOnHoverStyle: {
  //     backgroundColor: "#be150f22",
  //   },
  // },
  // subHeader: {
  //   style: {
  //     backgroundColor: "#f9f9f9",
  //     paddingRight: 0,
  //     paddingLeft: 0,
  //   },
  // },
  // pagination: {
  //   style: {
  //     backgroundColor: "#f9f9f9",
  //   },
  // },
}

export default tableStyle
