import PropTypes from "prop-types";
import React from "react";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import AlternateHome from "pages/Home/AlternateHome";


const Dashboard = props => {

  //meta title
  document.title = "Dashboard | KARA";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          /> */}
          <AlternateHome />
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
