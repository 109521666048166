import axios from "axios";
import { getApi } from "helpers/api.config";
import { catchError } from "helpers";

//post functions
export const addCategory = async (payload) => {
  return await axios
    .post(getApi.SETUP.ADD_CATEGORY, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err))
}

//get functions
export const getAllCategories = async (params) => {
  return await axios
    .get(`${getApi.SETUP.GET_ALL_CATEGORIES}?${params}`)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

export const getCategoryById = async (id) => {
  return await axios
    .get(getApi.SETUP.GET_CATEGORY_BY_ID(id))
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};

//put funtions
export const updateCategory = async (payload) => {
  return await axios
    .put(`${getApi.SETUP.UPDATE_CATEGORY}`, payload)
    .then(async ({ data }) => {
      return data;
    })
    .catch(err => catchError(err, err));
};
//delete funtions
export const deleteCategory = async (categoryId) => {
  return await axios
    .delete(`${getApi.SETUP.DELETE_CATEGORY(categoryId)}`,)
    .then(async ({ data }) => {
      return data
    })
    .catch(err => catchError("", err))
}