import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import * as setup from "models/setup";

import toast from "react-hot-toast";

const SidebarContent = props => {
  const [categoryList, setCategoryList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  //const [selectedCategory, setSelectedCategory] = useState("All")


  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  const getAllCategories = () => {
    setup.getAllCategories("")
      .then(resp => {
        const data = resp?.categories
        setCategoryList(data)
        // console.log(data)
      }).catch(err => {
        console.log(err)
        toast.error(err?.response?.data?.message)
      })

  }

  useEffect(() => {
    getAllCategories()
  }, [])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="px-2">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title text-white">{props.t("Thematic Areas")} </li>

            <li className={`p-1 rounded bg-${props.selectedCategory === "All" ? "secondary " : null} text-${props.selectedCategory === "All" ? "white " : "secondary"}`
            }
              onClick={() => { props.setSelectedCategory("All") }} >
              <a className={`my-1 mx-2 p-0 text-${props.selectedCategory === "All" ? "white " : "secondary"}`}> All</a>
            </li>
            {categoryList.map((item, key) => (
              <li key={key} className={`p-1 rounded bg-${props.selectedCategory === item?.categoryName ? "secondary " : null} text-${props.selectedCategory === item?.categoryName ? "white " : "secondary"}`
              }
                onClick={() => { props.setSelectedCategory(item?.categoryName) }} >
                <a className={`my-1 mx-2 p-0 text-${props.selectedCategory === item?.categoryName ? "white " : "secondary"}`}>  {item?.categoryName}
                </a>
              </li>
            ))}





          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func
};

export default withRouter(withTranslation()(SidebarContent));
