import axios from "axios";
import toast from "react-hot-toast";
import Swal from "sweetalert2";


export const submitToastRequest = (endpoint, payload, onSuccess, onError) => {
  axios
    .post(endpoint, payload)
    .then(response => {
      const data = response.data;

      toast.success(data?.message);

      onSuccess();
    })
    .catch(error => {
      onError();
      if (error.toJSON().message === 'Network Error') {
        toast.error('Network Error. Check Internet Connection');
      }
      else {
        toast.error(error.response?.data?.message);
      }
    });
};

export const submitPopupRequest = (endpoint, payload, onSuccess) => {
  axios
    .post(endpoint, payload, {
      headers: {
        "content-type": "application/json",
      }
    })
    .then(({ data }) => {

      Swal.fire({
        title: 'Success',
        icon: 'success',
        text: data.message,
      });

      onSuccess();
    })
    .catch((error) => {
      if (error.toJSON().message === 'Network Error') {
        toast.error('Network Error. Check Internet Connection');
        Swal.close();
      }
      else {
        Swal.fire({
          title: 'Error',
          icon: 'error',
          text: error.response?.data?.message || 'Failed to delete',
        });
      }
    });
};

export const catchError = (error, altMsg) => {
  if (error?.message === 'Network Error') {
    throw 'Network Error. Check Internet Connection';
  }
  else {
    throw error?.response?.message || altMsg;

  }
};